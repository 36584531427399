import React from 'react'

export default function Title({ children }) {
	return (
		<h1
			className="mb-8 mt-4 text-4xl font-black text-primary sm:text-6xl md:mt-8"
			dangerouslySetInnerHTML={{ __html: children }}
		/>
	)
}

import React from 'react'

import Layout from '../components/layout'
import Title from '../components/title'
import { replaceImageUrlWithCompressedVersion } from '../common/utils'
import KittenList from '../components/kitten-list'

export { Head } from '../components/head'

export default function Kitten({ pageContext }) {
	const { title, content, featuredImage } = pageContext

	const altText = featuredImage.node.altText

	const size = featuredImage.node.mediaDetails.sizes.find(
		(size) => 'medium' === size.name,
	)

	return (
		<Layout showKittenList={true}>
			<div>
				<Title>{title}</Title>
				<div className="mb-8 flex flex-col gap-8 sm:mb-16 md:flex-row">
					<img
						className="self-start"
						src={replaceImageUrlWithCompressedVersion(
							size.sourceUrl,
						)}
						alt={altText}
						width={size.width}
						height={size.height}
					/>
					<div
						className="text-xl opacity-70 md:text-2xl"
						dangerouslySetInnerHTML={{ __html: content }}
					/>
				</div>
			</div>
		</Layout>
	)
}
